import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/material';

import Link from '../../ActiveLink';

import DropDownMenu from './DropDownMenu';

import style from './style';
import Lottie from 'lottie-react';
import PulsatingDot from '../../../../content/lotties/PulsatinDot.json';

interface NavItemsInterface {
  name?: string;
  url?: string;
  menu?: any;
  megamenu?: boolean;
}

const NavItems = ({ name, url, menu, megamenu }: NavItemsInterface) => {
  const classes = style();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    megamenu && setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const options = {
    loop: true,
    autoplay: true,
  };
  const lottieStyle = {
    height: 60,
  };

  return (
    <>
      {' '}
      <Box
        position="relative"
        height="100%"
        onClick={handleOpen}
        onMouseLeave={handleClose}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {name === 'Courses' && (
          <div
            style={{
              position: 'absolute',
              left: '-35px',
              width: '50px',
            }}
          >
            <Lottie
              animationData={PulsatingDot}
              options={options}
              style={lottieStyle}
            />
          </div>
        )}
        <Link activeClassName={classes.active} href={url}>
          <li
            aria-controls={open ? 'menu-list-grow' : undefined}
            className={classes.link}
          >
            <span>{name}</span>
            {megamenu && <> {open ? <ExpandLess /> : <ExpandMore />} </>}
          </li>
        </Link>

        {open && <DropDownMenu menu={menu} />}
      </Box>
    </>
  );
};

export default NavItems;
