import React from 'react';
import { Box, Button, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { openChangePopup } from '../../../../actions/popup';
import { logout } from '../../../../actions/auth';
import { useAppDispatch, useAppSelector } from '../../../../hooks';

const useStyle = makeStyles((theme: any) => ({
  authOuterBox: {
    justifyContent: 'center',
    marginTop: '18px',
  },
  signUpBtn: {
    marginRight: theme.spacing(2),
  },
}));

const AuthenticationButton = ({ handleClose }) => {
  const loginDetails = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const classes = useStyle();

  return (
    <List>
      <ListItem className={classes.authOuterBox}>
        {loginDetails.isAuthenticated ? (
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => dispatch(logout())}
          >
            LOGOUT
          </Button>
        ) : (
          <Box width="100%">
            <Button
              disableElevation
              variant="contained"
              className={classes.signUpBtn}
              color="primary"
              // onClick={() => openChangePopup('SignupPopup')}
              onClick={() => {
                handleClose();
                dispatch(openChangePopup('SignupPopup'));
              }}
            >
              SIGN UP
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                handleClose();
                dispatch(openChangePopup('LoginPopup'));
              }}
            >
              SIGN In
            </Button>
          </Box>
        )}
      </ListItem>
    </List>
  );
};

export default AuthenticationButton;
