import React from 'react';
import { Box } from '@mui/material';

import Link from '../../ActiveLink';

import LazyImage from '../../LazyImage';

import style, { useStyle } from './style';

const NavLeft = () => {
  const classes = style();

  return (
    <Box height="100%" {...useStyle.leftBox}>
      <Box>
        <Link href="/">
          <LazyImage
            className={classes.logoBox}
            src={`/images/icons/novatrox.svg`}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default NavLeft;
