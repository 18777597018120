import React from 'react';
import { motion } from 'framer-motion';
import { Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

import NavItem from './NavItem';

const MultiSidebar = ({ data, name, setIsOpen }) => {
  return (
    <motion.div
      initial={{
        position: 'absolute',
        background: '#fff',
        height: '100%',
        width: '100%',
        left: '-100%',
        top: '0',
      }}
      style={{
        zIndex: 1600,
      }}
      animate={{ width: '100%', left: '0' }}
      exit={{ left: '-100%' }}
    >
      <Box
        height="100%"
        width="100%"
        position="absolute"
        top="0"
        bgcolor="#fff"
      >
        <Box padding="15px  0">
          <ListItem button onClick={() => setIsOpen(false)}>
            <ListItemText primary={<b>{name}</b>} />
            <ChevronLeft />
          </ListItem>
        </Box>

        <Divider />
        <List style={{ position: 'static', paddingLeft: '5px' }}>
          {data &&
            data.map((menuItem, key) => <NavItem {...menuItem} key={key} />)}
        </List>
      </Box>
    </motion.div>
  );
};

export default MultiSidebar;
