import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  navList: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    '& a': {
      height: '100%',
    },
    '& li': {
      height: '100%',
      display: 'flex',
      color: '#000',
      alignItems: 'center',
      cursor: 'pointer',
      // position: 'relative',
      padding: '12px 15px',
      '& span': {
        marginRight: '14px',
      },
    },
  },
  courseActive: {
    backgroundColor: '#eff6ff',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    '& > svg': {
      marginRight: 4,
    },
  },
  courseActive2: {
    color: theme.palette.primary.main,
  },
  active: {
    '& > span': {
      color: theme.palette.primary.main,
      position: 'relative',
      '&:after': {
        content: "''",
        width: '100%',
        left: 0,
        position: 'absolute',
        bottom: -10,
        height: 4,
        background: '#7F56D9',
        // left: '-6px',
      },
    },
  },
  link: {
    height: '100%',
    '&:hover *': {
      color: '#B692F6',
      // '&:after': {
      //   content: "''",
      //   position: 'absolute',
      //   bottom: 15,
      //   height: 4,
      //   background: 'red',
      //   left: '-6px',
      //   width: '100%',
      // },
    },
  },
}));
export default style;
