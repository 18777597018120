import React, { useState } from 'react';
import { Backdrop, Box, Divider, Fade, Modal, Typography } from '@mui/material';

import PrimaryButton from '../../MaterialUi/PrimaryButton';

import ActiveLink from '../../ActiveLink';

import style from './style';
import { sendEmailVerification } from '../../../../client/OneistoxApiClient';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { removePopup } from '../../../../actions/popup';

const VerifyEmailPopup = ({ open }) => {
  const dispatch = useAppDispatch();
  const loginDetails: any = useAppSelector((state) => state.auth);
  const classes = style();

  const [serverResponse, setServerResponse]: any = useState({});

  const handleResend = async () => {
    try {
      const res = await sendEmailVerification();
      setServerResponse(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      aria-labelledby="verify email popup"
      aria-describedby="verify email thankyou popup"
      open={open}
      className={classes.modalContainer}
      BackdropComponent={Backdrop}
      onClose={() => dispatch(removePopup())}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box borderRadius={2} textAlign="center" bgcolor={'tertiary.white'}>
          <Typography variant="h5" paragraph>
            {serverResponse.success ? 'Email sent!' : 'Verify your email'}
          </Typography>

          <Typography variant="h3" paragraph>
            {serverResponse.success ? '📨' : '🔐'}
          </Typography>
          <br />
          {serverResponse.success ? (
            <Typography variant="body1" paragraph>
              Tap the button in the email we sent to your registered id -
              {serverResponse?.email} to complete verification. <br />( Check
              Spam folder in case you do not see it in the inbox )
            </Typography>
          ) : (
            <>
              <Typography variant="body1" paragraph>
                Confirm it's really you by verifying your email id associated
                with the account - {loginDetails.userData.emailId}.
              </Typography>{' '}
              <Typography variant="body1" gutterBottom color="textSecondary">
                Haven't received the email yet ?
              </Typography>
              <PrimaryButton onClick={handleResend}>
                {' '}
                RESEND EMAIL
              </PrimaryButton>
            </>
          )}

          <Box mt={4} />

          <Divider />

          <Box pt={2}>
            <Typography variant="body1" color="textSecondary">
              If your email id is incorrect then contact us{' '}
              <ActiveLink href="/contact-us">
                <Typography component="span" color="primary">
                  here.
                </Typography>
              </ActiveLink>
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default VerifyEmailPopup;
