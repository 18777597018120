import React from 'react';
import LazyImage from '../../Layouts/LazyImage';

import { Box, Container, Grid, Typography } from '@mui/material';

import style from './style';

const DashboardFooter = (props) => {
  const classes = style();
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container item xs={12}>
          <div className={classes.logoBox} style={{ gridColumn: 'span 6' }}>
            <Box marginRight={'20px'}>
              <LazyImage src="/images/icons/novatrox.svg" alt="Novatr Logo" />
            </Box>
            <Box width={162} height={28} marginLeft={'6px'}>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                color={'textSecondary'}
              >
                Dare to Disrupt
              </Typography>
            </Box>
          </div>
          <div className={classes.logoBox} style={{ gridColumn: 'span 6' }}>
            <LazyImage
              sx={(theme) => ({
                '& > img': { width: '56px', height: '56px' },
                [theme.breakpoints.down('sm')]: {
                  '& > img': {
                    width: '56px',
                    height: '56px',
                  },
                },
              })}
              src="/images/icons/Union.svg"
              alt=""
            />
          </div>
        </Grid>
      </Container>
      <div className={classes.bottom}>
        <Typography variant="body2" align="center">
          &copy; 2023 Oneistox Network Pvt. Ltd.
        </Typography>
      </div>
    </footer>
  );
};

// const sizes = {
//   xxl: [219, 64],
//   sm: [118, 34],
//   xs: [197, 58],
// };
export default DashboardFooter;
