import { makeStyles } from '@mui/styles';

const style = makeStyles((theme) => ({
  divider: { width: '85%', margin: 'auto' },
  infoIcon: {
    marginBottom: 10,
    marginLeft: 12,
  },

  verifyEmail: {
    backgroundColor: '#fafafa',
    '& > div': {
      paddingTop: 12,
      paddingBottom: 12,
      display: 'flex',
      gridTemplateColumns: 'auto 1fr',
      gridGap: 12,
      alignItems: 'flex-start',
      '& > p': {
        wordBreak: 'break-all',
      },
    },
  },
  userProfileBox: {
    '& img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
  iconType: {
    maxWidth: '40px',
    /* width: 100%; */
    height: '40px',
    marginRight: '11px',
    backgroundColor: '#428aeb',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 9px',
    justifyContent: 'center',
  },
  courseTypeLabel: {
    '& > svg': {
      fontSize: 10,
      verticalAlign: 'middle',
      marginRight: 3,
    },
    position: 'absolute',
    top: '6px',
    left: '69px',
    fontSize: 7,
    textTransform: 'uppercase',
    borderRadius: 8,
    color: '#fff',
    padding: ' 2px 6px',
  },
  newCourse: { backgroundColor: '#7F56D9' },
  trendingCourse: { backgroundColor: '#d4bb42' },
  icon: {
    color: '#000',
  },
  navItems: {
    padding: '14px  16px',
  },
  myAccount: { padding: '20px  16px' },
  userBox: {
    // backgroundColor: '#eff6ff',
    minHeight: 85,
  },
  notification: {
    marginBottom: '8px',
  },
}));

export default style;
