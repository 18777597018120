import React from 'react';
import { Box } from '@mui/material';

import { makeStyles } from '@mui/styles';

import PrimaryButton from '../../MaterialUi/PrimaryButton';
import { openChangePopup } from '../../../../actions/popup';
import { useAppDispatch } from '../../../../hooks';

const LoginButton = ({}) => {
  const dispatch = useAppDispatch();
  const classes = useStyle();
  return (
    <Box display="flex" height="100%" alignItems="center">
      <PrimaryButton
        onClick={() => dispatch(openChangePopup('LoginPopup'))}
        title="Log in"
        disableElevation
        size="large"
        variant="outlined"
        className={classes.loginBtn}
      >
        Log in
      </PrimaryButton>
    </Box>
  );
};

const useStyle = makeStyles(() => ({
  loginBtn: {
    minWidth: '125px',
  },
}));

export default LoginButton;
