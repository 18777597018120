import React from 'react';
import { AppBar, Grid, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import NavLeft from './NavLeft';
import NavRight from './NavRight';
import NavCenter from './NavCenter/NavCenter';

interface Props {
  page?: string;
}

const Navbar = ({ page }: Props) => {
  const classes = useStyle();
  return (
    <AppBar
      position="sticky"
      className={`${classes.header}`}
      color="inherit"
      elevation={2}
      component="nav"
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={11} xl={10}>
          <Toolbar className={classes.toolbarBox}>
            <Grid
              className={classes.height}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid className={classes.height} item xs={5} md={1}>
                <NavLeft />
              </Grid>
              <Grid
                className={`${classes.height} ${classes.navContainer}`}
                container
                justifyContent="flex-end"
                item
                // style={{ maxWidth: mathches ? '65%' : '' }}
                xs={7}
                lg={'auto'}
              >
                {/* <Grid className={classes.height} item></Grid> */}
                {/* <Grid className={classes.height} item> */}
                <NavCenter />
                <NavRight page={page} />
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
};

const useStyle = makeStyles((theme: any) => ({
  header: {
    height: '100%',
  },
  navContainer: {
    [theme.breakpoints.up()]: {},
  },
  height: {
    height: '100%',
  },
  toolbarBox: {
    height: '64px',
    minHeight: '64px',
    justifyContent: 'space-between',
  },

  [theme.breakpoints.up('md')]: {
    toolbarBox: {
      minHeight: '72px',
    },
  },
}));

export default Navbar;
