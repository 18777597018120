import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Smartphone,
  PeopleOutline,
  LibraryBooksOutlined,
  LocalLibraryOutlined,
  ChevronRight,
  InsertDriveFile,
  InfoOutlined,
  BookmarkBorder,
  TrendingUp,
  NewReleases,
  Settings,
  Book,
  NotificationsNone,
  Person,
} from '@mui/icons-material';

import MultiSidebar from './MultiSidebar';
import Link from '../../ActiveLink';
import LazyImage from '../../LazyImage';

import { openChangePopup } from '../../../../actions/popup';
import VerifyEmailPopup from '../../Popups/VerifyEmailPopup';

import style from './style';
import { useAppSelector } from '../../../../hooks';

const icons = {
  Smartphone,
  PeopleOutline,
  LibraryBooksOutlined,
  InsertDriveFile,
  Book,
  ChevronRight,
  TrendingUp,
  NewReleases,
  Settings,
  InfoOutlined,
  BookmarkBorder,
  NotificationsNone,
  LocalLibraryOutlined,
  Person,
};

const MultiSideBarComp = ({ isOpen, name, data, setIsOpen }) => (
  <AnimatePresence>
    {isOpen && <MultiSidebar data={data} name={name} setIsOpen={setIsOpen} />}
  </AnimatePresence>
);

interface nameItemInterface {
  type?: string;
  icon?: string;
  children?: any;
  name?: string;
  url?: any;
  auth?: any;
  courseType?: any;
  iconSvg?: any;
  profile?: any;
}

const NavItem = ({
  type,
  icon,
  children,
  name,
  url,
  auth,
  iconSvg,
  profile,
}: nameItemInterface) => {
  const loginDetails: any = useAppSelector((state) => state.auth);

  const classes = style();

  const { personal_info } = loginDetails.userProfile;

  const [openVerifyPopup, setOpenVerifyPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const checkAuth = () => {
    if (loginDetails.isAuthenticated) {
      setIsOpen(true);
    } else {
      openChangePopup('LoginPopup');
    }
  };

  const MyIcon = () => {
    if (icon) {
      const Component = icons[icon];

      return <Component />;
    } else {
      return <></>;
    }
  };

  if (type === 'devider') {
    return (
      (!auth || loginDetails.isAuthenticated) && (
        <Divider className={classes.divider} />
      )
    );
  } else if (type === 'notification') {
    return (
      <>
        {/* (!auth || loginDetails.isAuthenticated) && (
        <ListItem className={classes.notification}>
          <Box {...notificationBox}>
            <ListItemIcon>
              <NotificationsNone />
            </ListItemIcon>
            <Box display="inline-flex">
              <Typography variant="body2">
                <Typography color="primary" component="span">
                  7
                </Typography>
                new notification
              </Typography>
            </Box>
          </Box>
        </ListItem>
      ) */}
      </>
    );
  } else if (type === 'user' && loginDetails.isAuthenticated) {
    return (
      <>
        <ListItem className={classes.userBox}>
          <Box className={classes.userProfileBox} {...iconBox}>
            {loginDetails.isAuthenticated ? (
              <>
                {personal_info?.profile_path ? (
                  <img
                    src={`${process.env.NEXT_PUBLIC_ASSETS_2}/${personal_info?.profile_path}`}
                    alt={loginDetails?.userProfile?.firstName}
                  />
                ) : (
                  <LazyImage
                    src={`/images/icons/man2.png`}
                    alt={loginDetails?.userProfile?.firstName}
                  />
                )}
              </>
            ) : (
              <Person />
            )}
          </Box>

          <ListItemText
            primary={
              <b style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                {loginDetails.isAuthenticated ? (
                  <span>
                    Hey! <br />{' '}
                    {/* <Badge
                    badgeContent={
                      !loginDetails?.email_verified ? (
                        <InfoRounded
                          className={classes.infoIcon}
                          fontSize="small"
                          htmlColor="#b00020"
                        />
                      ) : (
                        ''
                      )
                    }
                    > */}{' '}
                    {loginDetails?.userProfile?.firstName}
                    {/* </Badge> */}
                  </span>
                ) : (
                  'Hello, Sign in'
                )}
              </b>
            }
          />
        </ListItem>

        {/* {!loginDetails?.email_verified && (
          <ListItem
            button
            onClick={() => setOpenVerifyPopup(true)}
            className={classes.verifyEmail}
          >
            <div>
              <Email htmlColor="#b00020" />
              <div>
                <Typography variant="subtitle1">Verify your email</Typography>
                <Typography variant="caption" component="p">
                  Be part of our trusted <br />
                  community by verifying email id.
                </Typography>
              </div>
            </div>
          </ListItem>
        )} */}
        {openVerifyPopup && (
          <VerifyEmailPopup
            open={openVerifyPopup}
            // setOpenVerifyEmailPopup={() => setOpenVerifyPopup(false)}
          />
        )}
        <ListItem
          className={`${classes.myAccount}`}
          onClick={() => checkAuth()}
        >
          {icon && (
            <ListItemIcon className={classes.icon}>
              <MyIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={name} />
          <ChevronRight />
        </ListItem>
        <Divider className={classes.divider} />

        <MultiSideBarComp
          isOpen={isOpen}
          name={name}
          data={children}
          setIsOpen={setIsOpen}
        />
      </>
    );
  } else {
    return (
      (!auth || loginDetails.isAuthenticated) && (
        <>
          <Link href={profile ? `${url}?id=${loginDetails.userId}` : url}>
            <ListItem
              className={`${classes.navItems}`}
              onClick={() => !url && setIsOpen(true)}
            >
              {icon && (
                <ListItemIcon className={classes.icon}>
                  <MyIcon />
                </ListItemIcon>
              )}
              {iconSvg && (
                <LazyImage
                  className={classes.iconType}
                  src={`/images/icons/${iconSvg}`}
                />
              )}

              {/* {courseType && (
                <span
                  className={`${classes.courseTypeLabel} ${
                    courseType == 'new'
                      ? classes.newCourse
                      : classes.trendingCourse
                  }`}
                >
                  {courseType == 'new' ? <NewReleases /> : <TrendingUp />}
                  {courseType}
                </span>
              )} */}
              <ListItemText primary={`${name}`} />
              {children?.length > 0 && <ChevronRight />}
            </ListItem>
          </Link>
          <MultiSideBarComp
            isOpen={isOpen}
            name={name}
            data={children}
            setIsOpen={setIsOpen}
          />
        </>
      )
    );
  }
};

const iconBox = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  overflow: 'hidden',
  bgcolor: 'lightgrey',
  display: 'flex',
  marginRight: '12px',
  alignItems: 'center',
  justifyContent: 'center',
};

// const notificationBox = {
//   bgcolor: 'lightgrey',
//   display: 'flex',
//   alignItems: 'center',
//   borderRadius: 8,
//   padding: '5px 8px',
//   width: '100%',
// };

export default NavItem;
