import React, { useState, useEffect } from 'react';
import { Box, IconButton, SwipeableDrawer } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import Sidebar from '../../../Layouts/Sidebar/';
import LoginButton from './LoginButton';
import NavRightItem from './NavRightItem';
import { useAppSelector } from '../../../../hooks';
import PrimaryButton from '../../MaterialUi/PrimaryButton';

// import Search from './Search';

const NavRight = ({ page }) => {
  const loginDetails = useAppSelector((state) => state.auth);

  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  return (
    <>
      {page === 'course' && (
        // <div>
        <PrimaryButton
          disableElevation
          href="#registration"
          sx={{ alignSelf: 'center', display: { md: 'none' }, mr: 1 }}
        >
          Apply Now
        </PrimaryButton>
        // </div>
      )}

      <Box height="100%" display="flex">
        {/* <Search /> */}
        <Box display={{ lg: 'none' }}>
          <IconButton
            // edge="start"
            color="inherit"
            style={{ height: '100%' }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon style={{ fontSize: 32 }} />
          </IconButton>
        </Box>
        <SwipeableDrawer
          // left={'right'}
          swipeAreaWidth={5}
          anchor={'right'}
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Sidebar handleClose={toggleDrawer(false)} />
        </SwipeableDrawer>

        <Box height="100%" display={{ xs: 'none', lg: 'block' }}>
          {loginDetails.isAuthenticated ? <NavRightItem /> : <LoginButton />}
        </Box>
      </Box>
    </>
  );
};

export default NavRight;
