import React, { Fragment } from 'react';
import { Box } from '@mui/material';

import NavbarItem from './NavbarItem';
import style from './style';

import dropdownItems from '../../../../content/elements/navbar.json';

const NavCenter = () => {
  const classes = style();

  return (
    <Box display={{ xs: 'none', lg: 'flex' }} height="100%">
      <ul className={classes.navList}>
        {dropdownItems.navbarItem &&
          dropdownItems.navbarItem.map((dropdownItem, key) => (
            <Fragment key={key}>
              <NavbarItem {...dropdownItem} />
            </Fragment>
          ))}
      </ul>
    </Box>
  );
};

export default NavCenter;
