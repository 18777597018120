import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  footer: {
    background: '#f5f5f5',
  },
  logoBox: {
    padding: '28px 0 22px',
    minHeight: 104,
    '& img': {
      width: 127,
      marginBottom: 32.35,
    },
  },
  bottom: {
    background: '#e9e9e9',
    padding: '10px 0',
  },
  [theme.breakpoints.up('sm')]: {
    logoBox: {
      alignItems: 'start',
      minHeight: '204px',
      'logoBox img': {
        width: 197,
        marginBottom: 23,
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    logoBox: {
      minHeight: '226px',
      paddingTop: '41px',
      '& img': {
        width: 162,
        marginBottom: 4,
      },
    },
  },
}));

export default style;
