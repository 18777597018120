import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme: any) => ({
  myCourse: {
    backgroundColor: 'rgba(127, 86, 217, 0.3)',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    '& > svg': {
      marginRight: 4,
    },
  },
  userProfileBox: {
    '& img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
  courseActive: {
    backgroundColor: 'rgba(127, 86, 217, 0.3)',
    color: theme.palette.primary.main,
  },
  infoIcon: {
    marginLeft: '9px',
    marginBottom: '9px',
  },
  verifyEmail: {
    backgroundColor: '#fafafa',
    padding: 12,
    display: 'flex',
    gridTemplateColumns: 'auto 1fr',
    gridGap: 12,
    alignItems: 'flex-start',
    '& > p': {
      wordBreak: 'break-all',
    },
  },
  customeIconButton: {
    padding: '0',
    borderRadius: 0,
    '&:hover': {
      background: 'none',
    },
  },
  iconButton: {
    padding: '12px 16px',
  },
  dropdownBox: {
    minWidth: '276px',
    width: '100%',
  },
}));

export default useStyle;
