import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  logoBox: {
    '& > img': {
      width: '111px',
      height: '20px',
    },
    marginTop: '-2px',
    [theme.breakpoints.up('sm')]: {
      '& > img': { width: '127px', height: '32.35px' },
    },
  },
}));
export default style;

export const useStyle = {
  leftBox: {
    display: 'flex',
    alignItems: 'center',
  },
  IconButtonProp: {
    edge: 'start',
    color: 'inherit',
    'aria-label': 'open drawer',
  },
};
