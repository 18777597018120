import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  modalContainer: {
    width: '100%',
    maxWidth: '627px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      zIndex: 1,
    },
  },
}));
export default style;
