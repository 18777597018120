import React from 'react';
import { MenuList, Paper, MenuItem, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Link from '../../../ActiveLink';
import LazyImage from '../../../LazyImage';

const DropDownMenu = ({ menu }) => {
  const { items } = menu;

  const classes = useStyle();

  return (
    <Paper id="dropdownMenu" className={classes.dropdownBox}>
      <MenuList>
        {/* menuitems */}
        {items.map((item, key) => (
          <Link href={item.url} key={key}>
            <MenuItem>
              <LazyImage
                className={classes.iconBox}
                src={`/images/icons/${item.icon}`}
              />
              {item.name}
              {item.courseType === 'new' && (
                <Chip
                  label={
                    <span
                      style={{
                        marginRight: '-8px',
                      }}
                    >
                      NEW
                    </span>
                  }
                  sx={{
                    backgroundColor: 'rgba(255, 90, 54, 0.1);',
                    border: '1px solid #7F56D9',
                    color: '#7F56D9',
                    fontSize: '8px',
                    height: '15px',
                    marginLeft: 1,
                    // width: '40px',
                  }}
                />
              )}
            </MenuItem>
          </Link>
        ))}
      </MenuList>
    </Paper>
  );
};

export default DropDownMenu;
const useStyle = makeStyles(() => ({
  dropdownBox: {
    position: 'absolute',
    width: 470,
    top: '100%',
    padding: '13px 25px',
  },
  iconBox: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#9062FC',
    padding: 12,
    marginRight: 12,
  },
  courseTypeLabel: {
    '& > svg': {
      fontSize: 10,
      verticalAlign: 'middle',
      marginRight: 3,
    },
    position: 'absolute',
    top: '6px',
    left: '80%',
    fontSize: 7,
    textTransform: 'uppercase',
    borderRadius: 8,
    color: '#fff',
    padding: ' 2px 6px',
  },
  newCourse: { backgroundColor: '#7F56D9' },
  trendingCourse: { backgroundColor: '#d4bb42' },
}));
