import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

import AuthenticationButton from './AuthenticationButton';
import NavItem from './MultiSidebar/NavItem';
import LazyImage from '../LazyImage';
import Link from '../ActiveLink';

import menuItems from '../../../content/elements/sidebar.json';

const Sidebar = ({ handleClose }) => {
  const classes = useStyle();
  return (
    <Box
      width={{ xs: '290px', sm: '465px' }}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <List className={classes.listBox}>
        <ListItem>
          <LazyImage
            className={classes.logoBox}
            lazyLoadFalse
            src={`/images/icons/novatrox.svg`}
          />
        </ListItem>
        {menuItems &&
          menuItems.map((menuItem, key) => <NavItem {...menuItem} key={key} />)}
        <AuthenticationButton handleClose={handleClose} />
      </List>

      {/* bottom content  */}
      <List>
        <ListItem>
          <Box>
            <Typography variant="body2" component="p">
              Any questions for us ?
            </Typography>
            <Link href="/contact-us">
              <Typography variant="body2" component="span" color="primary">
                Contact us
              </Typography>
            </Link>
          </Box>
        </ListItem>
      </List>
      {/* bottom content  end */}
    </Box>
  );
};

export default Sidebar;

const useStyle = makeStyles((theme: any) => ({
  logoBox: {
    width: 100,
    height: 20,
    marginTop: theme.spacing(1),
    marginBottom: 15,
  },
  listBox: {
    '& .MuiListItem-gutters': {
      paddingLeft: 30,
    },
  },
}));
